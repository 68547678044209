import '../styles/index.scss';

var swiper = new Swiper('.swiper-container-index', {
    navigation: {
        nextEl: '.index-banner-button-next',
        prevEl: '.index-banner-button-prev',
    },
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
    },
});

var swiper_customer = new Swiper('.swiper-container-customer', {
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: {
        delay: 3500,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.customer-say-button-next',
        prevEl: '.customer-say-button-prev',
    },
});

var swiper_widget = new Swiper('.swiper-container-widget', {
    autoplay: {
        delay: 3500,
        disableOnInteraction: false,
    },
});

var topBarTop = 0;
var mfn_header_height = 0;
function mfn_sticky() {
    var start_y = mfn_header_height;
    var window_y = $(window).scrollTop();
    if (window_y > start_y) {
        if (!($('#main-navbar').hasClass('is-sticky'))) {
            $('#main-navbar').addClass('is-sticky').css('top', -60).animate({
                'top': 0
            }, 300);

            $('#mini-bar').addClass('is-sticky').css('bottom', -60).animate({
                'bottom': 0
            }, 300);
        }
    } else {
        if ($('#main-navbar').hasClass('is-sticky')) {
            $('#main-navbar').removeClass('is-sticky').css('top', topBarTop);

            $('#mini-bar').removeClass('is-sticky').css('bottom', -60);
        }
    }
}
mfn_sticky();
$(window).scroll(function() {
    mfn_sticky();
});

$("#scrollToTop").click(function() {
  $("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
});

/*$('.show_footer_big').click(function() {
    $('#footer_fly_small').css({'display':'none'});
    $('#footer_fly_big').css({'display':'block'});
});
$('.show_footer_small').click(function() {
    $('#footer_fly_small').css({'display':'block'});
    $('#footer_fly_big').css({'display':'none'});
});*/
